<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Evaluation Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
            style="margin-bottom: 0px !important;"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="mt-0">
                <b-row>
                  <b-col
                    cols="12"
                    xl="8"
                  >
                    <div>
                      <h3 class="text-primary invoice-logo">
                        {{ NameEvaluation != '' ? NameEvaluation : 'Name of Evaluation' }}
                      </h3>
                    </div>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="4"
                  >
                    <div>
                      <h5 class="text-primary invoice-logo">
                        {{ 'Evaluation applied to: ' }} <b>{{ userName }}</b>
                      </h5>
                      <h6 class="text-primary invoice-logo">
                        {{ 'With Supervisor: ' }}<b>{{ userSupervisor }}</b>
                      </h6>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="pt-0"
            >
              <b-row class="invoice-spacing">
                <!-- Col: Instructions -->
                <b-col
                  v-if="Instructions"
                  cols="12"
                  xl="4"
                >
                  <h6 class="mb-2">
                    Instructions to commit this evaluation:
                  </h6>
                  <div
                    class="mt-1"
                  >
                    <b-card-text class="mb-25">
                      {{ Instructions }}
                    </b-card-text>
                  </div>
                </b-col>
                <!-- Col: Category Details -->
                <b-col
                  v-if="Category"
                  xl="4"
                  cols="12"
                  class="mt-xl-0 mt-2"
                >
                  <div>
                    <h6 class="mb-2">
                      Category Details:
                    </h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            Name:
                          </td>
                          <td><span class="font-weight-bold">{{ Category.Name }}</span></td>
                        </tr>
                        <tr v-if="Category.Description">
                          <td class="pr-1">
                            Description:
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <b-card-text
                      v-if="Category.Description"
                      class="font-weight-bold"
                    >
                      Category Description is gonna be show in this place
                    </b-card-text>
                  </div>
                </b-col>
                <!-- Col: Staff Details -->
                <b-col
                  xl="4"
                  cols="12"
                  class="mt-xl-0 mt-2"
                >
                  <div>
                    <h6 class="mb-2">
                      Staff Details:
                    </h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            Total:
                          </td>
                          <td><span class="font-weight-bold">{{ Staffs.length }}</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
            <!-- Items Section -->
            <!-- Invoice Description: Table -->
            <b-overlay
              id="overlay-background"
              :show="showOverlay"
              variant="transparent"
              opacity="0"
              rounded="sm"
            >
              <template v-slot:overlay>
                <div />
              </template>
              <b-table-lite
                responsive
                fixed

                class="px-2"
                :items="invoiceDescription"
                :fields="fields"
              >
                <template #cell(Check)="data">
                  <div class="text-nowrap d-flex">
                    <b-form-checkbox
                      v-if="!data.item.activeRating"
                      v-model="data.item.checked"
                      :disabled="data.item.isNotApplied"
                    />
                  <!-- <b-card-text class="font-weight-bold mb-25">
                    {{ data.item.Weighing }} %
                  </b-card-text> -->
                  </div>
                  <div
                    v-if="data.item.activeRating"
                    class="text-nowrap d-flex"
                    style="align-items: center;"
                  >
                    <b-form-rating
                      v-model="data.item.selectedRating"
                      style="padding: 0px;"
                      no-border
                      show-clear
                      variant="warning"
                      :readonly="data.item.isNotApplied"
                      inline
                      precision="1"
                    />
                  </div>
                </template>
                <template #cell(notApply)="data">
                  <div class="text-nowrap d-flex">
                    <b-form-checkbox
                      v-if="data.item.isNotApplicable"
                      v-model="data.item.isNotApplied"
                    />
                  <!-- <b-card-text class="font-weight-bold mb-25">
                    {{ data.item.Weighing }} %
                  </b-card-text> -->
                  </div>
                </template>
                <template #cell()="data">
                  <b-card-text>
                    {{ data.value }}
                  </b-card-text>
                </template>
              </b-table-lite>

              <div
                style="margin-bottom: 15px;"
                class="pt-2 px-2"
              >
                <b-form-group
                  label="QA reviewer notes"
                  label-for="QA reviewer notes"
                >
                  <b-form-textarea v-model="Notes" />
                </b-form-group>
              </div>
            </b-overlay>
          </b-card>
        </b-form>
      </b-col>
      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >
        <b-card>
          <h5>Appt. ID (Caretrack)</h5>
          <div style="text-align: center; margin-bottom: 15px;">
            <b-form-input
              v-model="apptId"
              type="number"
              placeholder="#"
            />
          </div>
          <h5>8x8. Recording ID</h5>
          <div style="text-align: center; margin-bottom: 15px;">
            <b-form-input
              v-model="idEightByEight"
              type="text"
              placeholder="#"
            />
          </div>
          <h5>Account Number (eCW)</h5>
          <div style="text-align: center; margin-bottom: 15px;">
            <b-form-input
              v-model="accountNumber"
              type="number"
              placeholder="#"
            />
          </div>
          <h5 class="hidden">
            Date and Duration Time
          </h5>
          <div
            style="text-align: center; margin-bottom: 15px;"
            class="hidden"
          >
            <flat-pickr
              v-model="TimeDuration"
              class="form-control mb-1"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:S', time_24hr: true, enableSeconds:true}"
            />
          </div>
          <div>
            <b-button
              v-if="!showOverlay"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-75"
              block
              @click="idEvaluation === 0 ? EditEvaluation() : FinishEvaluation()"
            >
              {{ idEvaluation === 0 ? 'Edit Evaluation' : 'Finish Evaluation' }}
            </b-button>

          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, VBToggle, BTableLite, BFormCheckbox, BFormGroup, BFormTextarea, BFormInput, BFormRating, BOverlay,
} from 'bootstrap-vue'
import evaluationsService from '@core/services/evaluations/evaluationService'
import Swal from 'sweetalert2'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BTableLite,
    BFormCheckbox,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    flatPickr,
    BFormRating,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  data() {
    return {
      NameEvaluation: 'This is my Evaluation 1',
      Instructions: 'This are some instructions you have to Know',
      Notes: '',
      invoiceDescription: [],
      selected: [],
      idAgent: 0,
      idEvaluation: 0,
      Category: {},
      Staffs: {},
      dateNtim: null,
      TimeDuration: null,
      registration: '',
      userName: '',
      userSupervisor: '',
      apptId: '',
      idEightByEight: '',
      accountNumber: '',
      showOverlay: false,
      fields: [
        {
          key: 'section',
          label: 'Section',
          thStyle: { width: '10%' },
        },
        {
          key: 'concept',
          label: 'Concept',
          thStyle: { width: '10%' },
        },
        {
          key: 'description',
          label: 'Description',
          thStyle: { width: '45%' },
        },
        {
          key: 'Check',
          label: 'Score',
          thStyle: { width: '20%' },
        },
        {
          key: 'notApply',
          label: 'Not Applicable',
          thStyle: { width: '15%' },
        },
      ],
    }
  },
  computed: {
  },
  created() {
    this.LoadEvaluation()
  },
  methods: {
    LoadEvaluation() {
      const idEvaluationEdit = this.$store.getters['evaluations/getIdEvaluationApplytoEdit']
      if (idEvaluationEdit != null) {
        this.showOverlay = true
        evaluationsService.getUserEvaluationDetails({
          idEvaluation: idEvaluationEdit,
        }).then(response => {
          const { evaluationInfo, evaluationScores } = response.data
          this.userName = evaluationInfo.AppliedTo
          this.userSupervisor = evaluationInfo.supervisor.Name
          this.NameEvaluation = evaluationInfo.EvaluationName
          this.Instructions = evaluationInfo.ParentEvaluationDescription
          this.apptId = evaluationInfo.IdAppointment ? evaluationInfo.IdAppointment : ''
          this.idEightByEight = evaluationInfo.IdEightByEight ? evaluationInfo.IdEightByEight : ''
          this.accountNumber = evaluationInfo.AccountNumber ? evaluationInfo.AccountNumber : ''
          this.Notes = evaluationInfo.Description
          this.Category = evaluationInfo.category
          this.Staffs = evaluationInfo.staff
          const datetimeformated = moment(evaluationInfo.Duration).format('YYYY-MM-DD HH:mm:ss')
          this.TimeDuration = datetimeformated
          this.invoiceDescription = this.formatQuestionScores(evaluationScores)
          const dateregistered = moment(evaluationInfo.Registration).format('YYYY-MM-DD HH:mm:ss')
          this.registration = dateregistered
        })
      } else {
        const idEvaluation = this.$store.getters['evaluations/getIdEvaluationApply']
        const idAgent = this.$store.getters['profiles/getIdProfile']
        this.idEvaluation = idEvaluation
        evaluationsService.getEvaluationApplication({
          id: idEvaluation,
          idUser: idAgent,
        }).then(response => {
          this.userName = response.data.userInfo.Name
          this.userSupervisor = response.data.userInfo.Supervisor.Name
          this.NameEvaluation = response.data.evalBasicData.EvaluationName
          this.Instructions = response.data.evalBasicData.EvaluationDescription
          this.invoiceDescription = this.formatQuestions(response.data.evalQuestions)
          this.Category = response.data.evalCategory
          this.Staffs = response.data.evalStaff
        })
      }
    },
    formatQuestionScores(questions) {
      const questionsList = []
      questions.forEach(question => {
        const {
          SectionTitle, SectionValue, Id, Concept, Description, Value, checked, activeRating, Score, isNotApplicable, isNotApplied, isNegative,
        } = question
        const newQuestion = {
          IdQuestion: Id,
          activeRating,
          checked,
          concept: Concept,
          description: Description,
          id: Id,
          indicator: 0,
          section: SectionTitle,
          section_value: SectionValue,
          selectedRating: isNotApplied ? 0 : (Score * (10 / Value)) / 2,
          status: 1,
          value: Value,
          score: Score,
          isNotApplicable,
          isNotApplied: Boolean(isNotApplied),
          isAppliedNegative: false,
          isNegative,
        }
        questionsList.push(newQuestion)
      })
      // const sortedObjects = questionsList.sort((a, b) => a.section.localeCompare(b.section))
      const sortedObjects = questionsList.slice().sort((a, b) => {
        const sectionCompare = a.section.localeCompare(b.section)
        if (sectionCompare !== 0) {
          return sectionCompare
        }
        return a.concept.localeCompare(b.concept)
      })
      return sortedObjects
    },
    sortObjects() {

    },
    formatQuestions(sections) {
      const questions = []
      sections.forEach(section => {
        section.questions.forEach(question => {
          const questionObj = question
          questionObj.IdQuestion = question.id
          questionObj.section = section.title
          questionObj.section_value = section.value
          questionObj.selectedRating = 0
          questionObj.isNotApplied = false
          questions.push(questionObj)
        })
      })
      return questions
    },
    getPercentajeQuestions(questions) {
      // Percentage
      questions.forEach(question => {
        /* eslint-disable */
        const { checked, value, selectedRating, isNotApplied, isNegative  } = question
        if (checked) question.Percentage = value
        else question.Percentage = parseFloat((value*2) * (selectedRating/10)).toFixed(2)
        if (isNegative) question.Percentage = value - question.Percentage
        if (isNotApplied) question.Percentage = value
      })
      return questions
    },
    EditEvaluation() {
      let Text = ''
      /* eslint-disable */
      if (this.TimeDuration === null ||  this.TimeDuration === '00:00:00') {
        Text += `- Time Duration Missing<br>`
      }
      if (this.apptId === '') {
        Text += `- Appt ID Missing<br>`
      }
      if (Text !== '') {
        Swal.fire({
          icon: 'error',
          title: 'Missing',
          html: '<div class="lbedo">' + Text + '</div>',
          showConfirmButton: false,
        })
      } else {
        Swal.fire({
        title: 'Attention',
        text: 'Edit this Evaluation',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Edit',
      }).then(result => {
        if (result.isConfirmed) {
          evaluationsService.updateUserEvaluation({
            idEvaluation: this.$store.getters['evaluations/getIdEvaluationApplytoEdit'],
            registration: this.registration,
            duration: this.TimeDuration,
            description: this.Notes,
            headings: this.invoiceDescription,
            apptId: this.apptId,
            eightByEightId: this.idEightByEight,
            accountNumber: this.accountNumber,
          }).then(() => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Your Form has been edited',
              showConfirmButton: false,
            })
            this.$router.push({ name: 'profile-info' })
          })
        }
      })
      }
    },
    FinishEvaluation() {
      const idAgent = this.$store.getters['profiles/getIdProfile']
      let Text = ''
      /* eslint-disable */
      // if (this.TimeDuration === null ||  this.TimeDuration === '00:00:00') {
      //   Text += `- Time Duration Missing<br>`
      // }
      if (this.apptId === '') {
        Text += `- Appt ID Missing<br>`
      }

      if (Text !== '') {
        Swal.fire({
          icon: 'error',
          title: 'Missing',
          html: '<div class="warning_modal">' + Text + '</div>',
          showConfirmButton: false,
        })
      } else {
        Swal.fire({
        title: 'Attention',
        text: 'Finish this Evaluation',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Finish',
      }).then(result => {
        if (result.isConfirmed) {
          evaluationsService.ApplyEvaluation({
            idEvaluation: this.idEvaluation,
            idUser: idAgent,
            duration: this.TimeDuration,
            description: this.Notes,
            evalDetails: this.getPercentajeQuestions(this.invoiceDescription),
            apptId: this.apptId,
            eightByEightId: this.idEightByEight,
            accountNumber: this.accountNumber,
          }).then(() => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Your Form has been applied',
              showConfirmButton: false,
            })
            this.$router.push({ name: 'profile-info' })
          })
        }
      })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style>
.flatpickr-calendar .flatpickr-day.inRange, .flatpickr-calendar .flatpickr-day.inRange:hover{
  color: white;
}
.hms{
    font-size:20px;
    color:#000;
}
@media (max-width: 1200px){
    .stopWatch{
        justify-content: start !important;
        margin-top: 15px;
    }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

.b-time .b-time-ampm {
    margin-left: 0.5rem;
    display: none !important;
}
</style>
