import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class EvaluationService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    getEvalutionsByCategory(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getEvalutionsByCategory, ...args)
    }

    getEvaluationBasicInfo(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getEvaluationBasicInfo, ...args)
    }

    getEvaluationUserMetrics(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getEvaluationUserMetrics, ...args)
    }

    createEvaluation(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.createEvaluation, ...args)
    }

    getEvaluationById(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getEvaluationById, ...args)
    }

    updateEvaluation(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.updateEvaluation, ...args)
    }

    getEvaluationStaff(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getEvaluationStaff, ...args)
    }

    getEvaluationApplication(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getEvaluationApplication, ...args)
    }

    ApplyEvaluation(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.ApplyEvaluation, ...args)
    }

    getUserEvaluations(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getUserEvaluations, ...args)
    }

    getUserAvgHeadingScore(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getUserAvgHeadingScore, ...args)
    }

    getUserEvaluationDetails(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getUserEvaluationDetails, ...args)
    }

    updateUserEvaluation(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.updateUserEvaluation, ...args)
    }
}

function useEvaluationService() {
  const evaluationService = new EvaluationService()

  return {
    evaluationService,
  }
}

const { evaluationService } = useEvaluationService()
export default evaluationService
